import * as React from 'react';
import Loader from './Loader'

import './css/RmStep.css'
import Expander_helper from './Expander_helper'
import * as ReactDOMServer from 'react-dom/server'
import Rm20Headers from './Rm20Headers'

import { Anchorme } from 'react-anchorme'

interface MyProps {
    tup:any,
    substeps?:Array<any>,
    options:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean
    value:string
}

class RmStep extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	//this.on_input_change = this.on_input_change.bind(this)
	this.click_edit_btn = this.click_edit_btn.bind(this)
	this.state = {
	    is_loading:false,
	    value: ''
	}
    }
    componentDidMount(){
	let v = this.props.tup.winner
	
	if(v === undefined || v === null){
	    return
	}
	this.setState({value:v})
    }
    
    componentDidUpdate(prevProps:any) {
	if(prevProps.value !== this.props.tup.winner) {
	    this.setState({value: this.props.tup.winner});
	}
    }
    click_instructions(e:any){
	let parent = e.target.closest('.instructions-wrapper')
	console.log(e.target)
	if(parent !== null){
	    if(parent.classList.contains('collapsed')){
		parent.classList.remove('collapsed')
	    }else{
		parent.classList.add('collapsed')
	    }
	}
    }

	

    get_instructions(){
	let txt = this.props.tup.instructions
	return this.render_instructions(txt)
    }
    render_instructions(txt:string){
	if( typeof txt === 'string' && txt.length > 1){
	    let t2 = "   " + txt
	    return (
		<div className="instructions-wrapper collapsed">
		    <div className="cf-button" onClick={this.click_instructions}>zie bron</div>
		    <div className="body instructions">
			<Anchorme target="_blank" >{t2}</Anchorme>
		    </div>
		</div>
	    )
	}
    }
    click_edit_btn(e:any){
	console.log('test')
	let lbl = this.props.tup.label
	var d :{[k:string]:any}= {
	    acfkey: this.props.tup.key,
	    label:lbl,
	    top3: [],
	    cmd: "number-vote",
	    aspect: "huidig",
	    trigger_recalc: true,
	}

	if(this.props.tup.cft_identifier === "txt-input"){
	    d['cmd'] = "text-vote"
	}
	if(this.props.tup.cft_identifier === "comment-input"){
	    d['cmd'] = "comment-vote"
	    d['acf_key_step'] = this.props.tup.key
	}
	if(this.props.tup.cft_identifier.includes("option-input")){
	    d['cmd'] = 'choice-vote'
	    if(this.props.tup.select_key === undefined){
		d['acf_key_select'] = this.props.tup.key
	    }else{
		d['acf_key_select'] = this.props.tup.select_key
	    }
	    d['acf_key_step'] = this.props.tup.key
	}
	this.props.cb_parent(d,(r:any)=>{
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		if(r.winner !== undefined ){
		    this.setState({value:r.winner})
		}
	    }
	})
    }
    /*
    on_input_change(e:any){
	if(this.props.tup.cft_identifier === "num-input"){
	    let v = e.target.value
	    if(!isNaN(Number(v))){
		this.setState({value: v})
	    }
	}
	}
	*/
    show_option_text(){
	let tup = this.props.tup
	if(tup.calculated === undefined){
	    return (<div> no calc </div>)
	}
	let args = this.props.tup.calculated.level1.default.args
	console.log(args)
	let keys = Object.keys(args)
	if(keys.length > 0){
	    let k0 = keys[0]
	    let v0 = args[k0]
	    console.log(v0)
	    let v0s = v0.ret.string
	    
	    console.log(`v0s : ${v0s}`)
	    if(v0.choices !== undefined){
		let lab = v0.choices[v0s]
		return (<div> {lab} </div>)
	    }
	    return (<div>  {v0s} </div>)
	}

	//let lab = arg0.choices[choice]
	return (
	    <div> - undef level1 args </div>
	)
    }
    render_body(){
	let tup = this.props.tup
	let part1 = (<div></div>)
	let div_input = (<div></div>)

	if(tup.cft_identifier.includes("num-input")
	    || tup.cft_identifier.includes("option-input")
	    || tup.cft_identifier === "comment-input"
	    || tup.cft_identifier === "txt-input")
	{
	    let txt = tup.cft_identifier.includes("default") ? "Of je eigen keuze" : "Keuze"
	    let val = this.state.value
	    let defval = ""
	    if(tup.cft_identifier.includes("option-input")){
		if(typeof val === 'string' && val !== ""){
		    val = tup.choices[val]		    
		}else{
		    defval = tup.choices[tup.default_value]
		}
	    }
	    if(tup.append === '%'){
		val = (Number(val)*100).toFixed(0)
	    }
	    let default_val_el = null
	    if(this.state.value === null || this.state.value === ""){
		defval = tup.default_value
	    }
	    if(defval !== ""){
		default_val_el = (<div> Default value: {defval}</div>)
	    }
		
	    div_input = (
		<div className="num-input"> {default_val_el} <span>{txt}: </span>
		    <span>{val} {this.props.tup.append}</span>
		    <div className="cf-button" onClick={this.click_edit_btn}>Wijzig</div>
		</div>
	    )
	}

	if(tup.classes.includes('postmeta')){
	    part1 = (<div>{tup.value}</div>)
	}
	if(tup.cft_identifier.includes("calc-default")){
	    part1 = this.render_calc_single(tup)
	}
	else if(tup.cft_identifier === "calc-ref"){
	    if(tup.classes.includes('show-left')){
		return (
		    <div className="showing-left">
			{this.render_calc_ref_single(tup)}
		    </div>
		)
	    }	    
	}
	else if(tup.cft_identifier === "calculation"){
	    if(tup.classes.includes('show-option-text')){
		return this.show_option_text()
	    }
	    if(tup.classes.includes('show-left')){
		return (
		    <div className="showing-left">
			{this.render_techsetting()}
			{this.render_calc_single(tup)}
		    </div>
		)
	    }
	    if(tup.calculated === undefined){
		return (<div>ERROR calculated undefined </div>)
	    }
	    let cols = tup.calculated.level1
	    if(cols === undefined){
		cols = tup.calculated
	    }
	    return (
		<div className="logic-with-cols level-1">
		    {this.render_logic()}
		    {this.render_calculation_columns(tup,cols)}
		</div>
	    )
	}

	if(tup.cft_identifier === "global-num" || tup.cft_identifier.includes("global-default")){
	    let v = Number(tup.value).toFixed(0)

	    let append = tup.append === undefined ? "" : tup.append
	    if(tup.classes.includes("percentage")){
		append = '%'
	    }
	    if(tup.append === '%' || tup.classes.includes("percentage")){
		v = (Number(tup.value)*100).toFixed(0)
	    }else{
		let precision = this.get_precision()
		v = Number(tup.value).toFixed(precision).replace('.',',')
	    }
	    let pre = tup.prepend === undefined ? "" : tup.prepend
	    if(pre === '€' && tup.value < 0){
		// swap - and €
		v = v.substr(1)
		pre = '-€'
	    }
	    
	    let nvt = ""
	    let nvt_class = ""
	    if(this.props.tup.classes.includes('tech-setting')){
		if(! this.props.tup.techniek){
		    nvt = "n.v.t."
		    nvt_class = "disabled"
		}
	    }


	    part1  =  (
		<div className={nvt_class} >
		    <div>Brongetal : {pre} {v} {append} {nvt}</div>
		{this.get_instructions()}
		</div>
	    )
	}
	return (
	    <div>
		{this.render_logic()}
		{this.render_techsetting()}
	        {part1} {div_input}
	    </div>
	)
    }
    render_calc_ref_single(tup:any){
	if(tup.calculated === null ){return null}
	if(tup.calculated.result === null ){return null}
	
	let ctup:any = tup.calculated.result	
	let col = ""
	if(tup.calculated.result.value === undefined){
	    let res = Object.entries(tup.calculated.result)
	    col = tup.calculated.col
	    if(col !== undefined){
		if(ctup[col] !== undefined){
		    ctup = ctup[col]
		}
	    }else{
		ctup = res[0][1]
		console.log('calc-ref-single')
		console.log(ctup)
		console.log(tup.calculated.slug0)
	    }

	}
	return (
    	    <div className="calc-row-wrapper calc-ref">
		<div className="calc-value">
		{this.render_calc('berekend',ctup,"",99,tup.slug)}
	    </div>
	    	<div className="traces">
		{this.render_calc_trace_clone(ctup,tup.slug,tup.calculated.slug0,tup.calculated.tech,col)}

		</div>
		</div>
	)
    }

    render_logic(){
	let calc = this.props.tup.calculated
	if(calc === undefined
	    || calc.level1 === undefined
	    || calc.level1.logic === undefined
	  ){
	    return (
		<div className="calc-row-wrapper logic">
		    </div>
	    )
	}
	    
	let ctup = calc.level1.logic
	return (
	    <div className="calc-row-wrapper logic">
		{this.render_calc('Is actief?',ctup,"",1,"logic")}
	    	<div className="traces">
		{this.render_calc_trace(ctup,"",1,"logic")}
	    </div>
	    </div>
	)

    }
    get_precision(){
	let tup = this.props.tup
	for(var cl of tup.classes){
	    if(cl.includes("precision")){
		let x = cl.split('-')
		return Number(x[1])
	    }
	}
	return 0
    }
    render_techsetting(){
	if(this.props.tup.classes.includes('tech-setting')){
	    let v = this.props.tup.techniek_str
	    return (
		<div className="tech-setting">{v}</div>
	    )
	}
    }
    render_calc_single(tup:any){
	if(tup.calculated === undefined){
	    return (<div> error calculated is undefined </div>)
	}
	let calculated = tup.calculated
	if(tup.calculated !== undefined){
	    if(tup.calculated.level1 !== undefined){
		calculated = tup.calculated.level1
	    }
	}
	if(calculated === undefined){ return (<div></div>) }

	let append = tup.append
	// calculation columns other than 'logic'
	let calc_keys = Object.keys(calculated).filter((x) => x !== 'logic')
	let multiple = calc_keys.length > 1
	if(multiple){
	    return (<div> ERROR expect no columns in calc-single </div>)
	}
	let cname = calc_keys[0]
	let ctup = calculated[cname]
	return (
	    <div className="calc-row-wrapper logic">
		{this.render_logic()}
		<div className="calc-value">{this.render_calc('Berekend:',ctup,append,1,"x")} </div>
	    	<div className="traces">
		{this.render_calc_trace(ctup,"",1,"x")}
	    </div>
		</div>
	    
	)
    }
    click_explain(e:any,cname:string){
	console.log(` explain cname ${cname} `)

	let parent = e.target.closest('.calc-row-wrapper')
	if(this.props.tup.classes.includes('with-function-block')){
	    console.log('click with fb')
	    console.log(parent)
	    let trg = parent.querySelector('.traces .trace')
	    console.log(trg)

	    if(trg.classList.contains('show')){
		trg.classList.remove('show')
	    }else{
		trg.classList.add('show')
	    }
	    
	    return
	}
	
	let was_selected = e.target.classList.contains('selected')	
	if(parent === null){return}
	// remove class selected for all values
	parent.querySelectorAll('.value').forEach((x:Element)=>x.classList.remove('selected'))
	// remove class show for all trace-bodies
	parent.querySelectorAll('.traces .trace').forEach((x:Element)=>x.classList.remove('show'))
	let trg = parent.querySelector(`.traces .trace.${cname}`)
	
	if(was_selected){
	    e.target.classList.remove('selected')
	    trg.classList.remove('show')
	}else{
	    e.target.classList.add('selected')
	    trg.classList.add('show')
	}
	
    }
    get_page_for_mod(modul:string,tech?:string):string{
	let sep = '&'
	let map : {[k:string]:string}= {
	    'basis' : '?hdtab=rm_basis',
	    'techniek' : '?hdtab=rm_tech',
	    'summary' : '?hdtab=rm_summary'
	}
	if(this.props.options.config.devmode){
	    sep = '?'
	    map = {
		'basis' : 'rm_v2.html',
		'techniek' : 'rm_v2t.html',
		'summary' : 'v2_summary.html'
	    }
	}
	if(map[modul] === undefined){return ""}
	return map[modul] + sep + 'techniek=' + tech

    }
    click_field_ref(slug:string,e:any,tech?:string){
	if(tech===undefined){
	    tech = this.props.options.techniek.slug
	}
	let modul = this.get_fmodule(slug)
	let level1 = 'undef'
	console.log(`click field ref ${slug} / ${modul}`)
	if(modul !== this.props.options.module){
	    let page  = this.get_page_for_mod(modul,tech)
	    if(page === ""){
		console.log('error, no page found')
		return
	    }
	    console.log('not same page, got page '+page)
	    let url = page
		+ '&fld=' + slug
	    let win = window.open(url, '_blank')
	    if(win){
		win.focus()
	    }
	    return
	}
	    

	document.querySelectorAll('.highlight').forEach((e)=> e.classList.remove('highlight'))
	let elm = document.querySelector('div.slug_'+slug)

	if(elm instanceof HTMLElement){
	    let section = elm.closest('.section')
	    if(section instanceof HTMLElement){
		section.classList.remove('collapsed')
	    }
	    //
	    elm.classList.add('highlight')
	    console.log( `top: ${elm.offsetTop}`)
	    window.scroll({
		top: elm.offsetTop,
		left: 0,
		behavior: "smooth",
	    });
	}
    }
    get_flabel(slug:string){
	let tup = this.props.options.findex[slug]
	if(tup !== undefined){
	    return tup.label
	}
	tup = this.props.options.fbindex[slug]
	if(tup !== undefined){
	    return tup.label
	}
	
	return null
    }
    get_fmodule(slug:string){
	let tup = this.props.options.findex[slug]
	if(tup !== undefined){
	    return tup.module
	}
    }
    /*
      */
    render_calc_trace_clone(ctup:any,cname:string,slug0:string,tech?:string,col?:string){
	let v2 = ctup.value
	let label = this.get_flabel(slug0)
	let techlabel = ""
	let col_label = ""

	if(col !== undefined){
	    col_label = "Kolom: "+ this.get_col_label(slug0,col)
	}
	
	if(tech !== undefined){
	    if(tech !== this.props.options.techniek.slug){
		if(this.props.options.techindex[tech] !== undefined){
		    techlabel = this.props.options.techindex[tech]
		}
	    }
	}
	let tech_txt = (
	    <div>
		{techlabel === "" ? "" : "Techniek: "+techlabel}
	    </div>
	)
	return (
	    <div className={"trace clone "+cname}>
	    	<div onClick={(e)=>{ this.click_field_ref(slug0,e,tech)}} className="arg option" >
		{label} {tech_txt} {col_label}
	    </div>
          </div>
	)
    }
    click_fb_header(e:any,col:string){
	console.log('click fb-header to expand '+col)
	let p = e.target.closest('.fb-trace')
	console.log(p)
	let just_collapse = false
	if(e.target.classList.contains('selected')){
	    console.log('collapse')
	    just_collapse = true
	}
	
	p.querySelectorAll('.fb-col').forEach((e:HTMLElement)=>{
	    e.classList.remove('selected')
	})

	let trg = p.querySelector('.expand')
	if(trg.classList.contains('collapsed')){
	    trg.classList.remove('collapsed')
	}
	p.querySelectorAll('.fb-trace-cell').forEach((e:HTMLElement)=>{
	    e.classList.add('hide')
	})
	if(just_collapse){return}
	e.target.classList.add('selected')
	p.querySelector('.fb-trace-cell.'+col).classList.remove('hide')
	    
    }
    render_fb_trace_row(k:string,rowtup:any,i:number){
	if(rowtup === undefined){
	    console.log('ctup undefined')
	    return null
	}


	let row = this.props.options.fbindex[k]
	let label = row.label

	if(label === 'output'){ return null}
	let colsel = this.props.options.bouwlagensleutel;

	let count = Object.entries(rowtup).length
	return (
	    <div key={i} className="fb-trace">
		<div className="fb-trace-header">
		    <div className="header" >
		<div className="label">{label}</div>
		    </div>
		<div className={"calculations fb grid-"+count}>
			{Object.entries(rowtup).map(([k,ctup]:[string,any],j:number) => {
			    let val = this.rounded_value(ctup,row.append,"")
			    return (
				<div key={j} className={"fb-col"}  onClick={(e:any)=>this.click_fb_header(e,k)}>{val}</div>
			    )
			})}
		    </div>
		</div>
		<div className="calculations expand fb  collapsed">
		    {Object.entries(rowtup).map(([k,tup]:[string,any],j:number) =>
			this.render_fb_trace_cells(k,tup,row,j)
		    )}
		</div>
	    </div>
	)
    }

    render_fb_trace_cells(k:string,ctup:any,row:any,i:number){
	let val = this.rounded_value(ctup,row.append,"")
	return (
	    <div className={"fb-trace-cell "+k} key={i}>
		<div className="subheader"><div className="label">Rekenvariabelen:</div></div>
		{this.render_rekenvars(ctup.args)}
		
	    	<div className="subheader"><div className="label">Formule</div></div>
		<div>{ctup.trace1} = </div>
		<div>{ctup.trace2} = {val}</div>
	    </div>
	)
    }
    render_function_block_trace(append:string,i:number,cname:string){
	let fb = this.props.tup.functionblock
	if(fb == null){return null}
	return (
	    <div key={i} className={"trace "+cname}>
		<Rm20Headers key={98} options={this.props.options} mode="avo" view="" />
		{Object.entries(fb).map(([k,tup]:[string,any],i:number) => {
		    return this.render_fb_trace_row(k,tup,i)
		})}
	    </div>
	)
    }
    get_col_label(slug:string,colkey:string){
	let findex = this.props.options.findex
	let f = findex[slug]
	for(var x of Object.keys(this.props.options.columnsets)){
	    if(f.classes.includes(x)){
		let colset = this.props.options.columnsets[x]
		return colset[colkey]
	    }
	}
    }
    render_rekenvars(args:any){
	return Object.entries(args).map(([k,tup]:[string,any],i:number) => {
	    let v = tup.ret.value
	    let v2 = v

	    
	    let slug = tup.ref
	    if(tup.ret.type !== "text" && v!==null){
		if( typeof v === "number"){
		    v2 = v.toFixed(2).replace('.',',')
		}
	    }
		
	    let slug0 = slug.split(':')[0]
	    let col = (<div></div>)
	    if(slug.split(':').length > 1){
		let colkey = slug.split(':')[1]
		let col_txt = ''
		if(tup.ret.level == 2){
		    // colkey is not a ref to a column but to another function
		    col_txt = this.get_flabel(colkey)
		}else{
		    
		    if(colkey === 'tech_setting'){
			col_txt = 'Is actief?'
		    }else{

			let col_label = this.get_col_label(slug0,colkey)
			col_txt = colkey == 'logic' ? 'Is-actief?' : 'Kolom: '+col_label
		    }
		}
		col = (<div className="col">( {col_txt} )</div>)
	    }else{
		if(tup.ret.type === "text"){
		    // e.g.  for operator get_field(row,column)
		    let lab0 = this.get_flabel(tup.ret.value)
		    if(lab0 !== null){
			slug0 = tup.ret.value
		    }
		}
	    }

	    
	    if(slug0.startsWith('fb-input')){
		console.log('fb-input')
		if(tup.ret.slug !== undefined){
		    slug0 = tup.ret.slug
		    console.log(' fb-input slug: '+slug0)
		}
	    }
	    
	    let lab = this.get_flabel(slug0)


	    
	    if(tup.ret.type === 'option'){
		let opt_slug = tup.ret.string
		if(tup.choices === undefined){
		    console.log('oops ! choices undef for '+tup.name)
		}else{
		    let opt_label = tup.choices[opt_slug]
		    return (
			<div onClick={(e)=>{ this.click_field_ref(slug0,e)}} className="arg option" key={i}>{k}:   {lab}  : {opt_label}
			    <div className="value">= {v2}</div>
			    </div>
		    )
		}
	    }
	    let tech = ""
	    if(tup.tech !== undefined){
		if(tup.tech !== this.props.options.techniek.slug){
		    tech = ` (Tech ${tup.tech} )`
		}
	    }

	    return (
		<div className="arg" onClick={(e)=>{ this.click_field_ref(slug0,e,tup.tech)}} key={i}>{k}:   {lab} {col} {tech}
		    <div className="value">= {v2}</div>
		    </div>
	    )
	})
    }
    render_calc_trace(ctup:any,append:string,i:number,cname:string){
	if(this.props.tup.classes.includes('with-function-block')){
	    return this.render_function_block_trace(append,i,cname)
	}
	if(this.props.tup.is_clone){
	    let slug0 = this.props.tup.orig_name
	    return this.render_calc_trace_clone(ctup,cname,slug0)
	}

	let val = this.rounded_value(ctup,append,cname)

	if(ctup.args === undefined){ return null}
	return (
	    <div key={i} className={"trace "+cname}>
		<div className="header"><div className="label">Rekenvariabelen:</div></div>	    
		{this.render_rekenvars(ctup.args)}
	    	<div className="header"><div className="label">Formule:</div></div>
		<div>{ctup.trace1} = </div>
		<div>{ctup.trace2} = {val}</div>
	</div>
	)
    }
    rounded_value(ctup:any,append:string,cname:string){
	
	if(this.props.tup.classes !== undefined && this.props.tup.classes.includes('text-value')){
	    return ctup.value
	}
	let precision = cname === "logic" ? 0 : this.get_precision()
	if(append === '%'){
	    return Number(100* ctup.value).toFixed(precision) + '%'
	}else{
	    return Number(ctup.value).toFixed(precision).replace('.',',')	    
	}
    }
    render_calc(txt:string,ctup:any,append:string,i:number,cname:string){
	let exp = new Expander_helper();
	let val = this.rounded_value(ctup,append,cname)
	let pre = this.props.tup.prepend
	
	if(cname === "logic"){
	    append = ""
	    pre = ""
	}
	if(append === "%"){
	    append = ""
	}
	if(pre === '€' && ctup.value < 0){
	    // swap - and €
	    val = val.substr(1)
	    pre = '-€'
	}
	var default_hide_mode = false

	for(var c of this.props.tup.classes){
	    if(c.startsWith("show-col")){
		default_hide_mode = true
	    }
	}
	let classes  = "calculation with-expand collapsed col-"+cname
	if(default_hide_mode){
	    let cl0 = "show-col-" + cname
	    if(! this.props.tup.classes.includes(cl0)){
		classes += " hidden"
	    }
	}else{
	    let cl0 = "hide-col-" + cname

	    if(this.props.tup.classes !== undefined && this.props.tup.classes.includes(cl0)){
		classes += " hidden"
	    }
	}

	for(var cl of this.props.tup.classes){
	    if(cl.startsWith("col-")){
		let x = cl.substring(4)
		let colset = this.props.options.columnsets[x]
		if(x !== undefined){
		    val = colset[val]
		}
	    }
	}

	return (
	    <div className={classes} key={i}>
		<div className={"value "+cname} onClick={(e)=>{this.click_explain(e,cname)}}>{txt} {pre} {val} {append}</div>
	    </div>
	)
    }
    render_calculation_columns(tup:any, calculated:any){
	//let calculated = tup.calculated.level1
	if(calculated === undefined){return null}
	let append = tup.append
	let size = Object.entries(calculated).length
	let multiple = size > 1
	let colmap : {[name:string]:string}= {}
	for(var x of Object.entries(this.props.options.columnsets)){
	    let [k,choices]:[string,any] = x
	    if(tup.classes.includes(k)){
		colmap = choices
		size = Object.entries(choices).length
	    }
	}
	
	let gridsize = "grid-"+size

	
	let traces = this.props.tup.classes.includes('with-function-block')
	    ?  this.render_function_block_trace(append,102,"cname")
	    : Object.entries(calculated).map(([cname,ctup]:[string,any],i:number) => {
		if(ctup.type === "skipped-col"){ return null }
		return this.render_calc_trace(ctup,append,i,cname)
	    })
	return (
	    <div className="calc-row-wrapper">
		{this.render_techsetting()}
	    <div className={"calculations "+gridsize}>
		{Object.entries(calculated).map(([cname,ctup]:[string,any],i:number) => {
		    if(ctup.type === "skipped-col"){
			return (<div key={i} className="skip"></div>)
		    }
		    let collabel = colmap[cname]
		    //let txt = multiple ? (collabel === undefined ? cname  : collabel): 'Berekend'
		    let txt = ""
		    if(cname === "logic"){
			return null
		    }
		    return this.render_calc(txt,ctup,append,i,cname)
		})}
	    </div>
		<div className="traces">
		
		{traces}
		    </div>
		</div>
	)
    }
    render_substeps(){
	if(this.props.substeps === undefined){ return null }
	return this.props.substeps.map((tup:any,i:number) => {
	    let ival = tup.value
	    if(tup.append === '%'){
		ival *= 100
	    }
	    let precision = 0
	    let sval = Number(ival).toFixed(precision).replace('.',',')
	    return (
		<div key={i} className="substep">
		    {tup.label}: {tup.prepend} {sval} {tup.append}
		    {this.render_instructions(tup.instructions)}
		</div>
	    )
	})
    }
    render_l2_calc(){
	if(this.props.options.module === "summary"){return null}
	let tup = this.props.tup
	if(tup.calculated === undefined){
	    return null
	}
	if(tup.calculated.level2 === undefined){
	    return null
	}
	let l2 = tup.calculated.level2
	let l2keys = Object.keys(l2)
	return l2keys.map((k:string,i:number) => {
	    let lab = this.props.options.l2names[k]
	    if( lab === undefined){
		lab = k
	    }
	    return (
		<div key={i} className="level2-calc">
		    <div className="label">{lab}</div>
		    {this.render_calculation_columns(tup,l2[k])}	        
		</div>
	    )
	})
    }
    render(){
	let loader = (<Loader />)
	let label_txt = this.props.tup.label
	let slug = this.props.tup.slug
	if(this.props.tup.cft_identifier === "sub-header"){
	    return (
		<div className="rm-step sub-header">
	    	    <div className="header" >
		    <div className="label">{label_txt}</div>
		    </div>
		    </div>
	    )
	}
	let cls = ""
	let include_classes = ['sub-header'];
	for(var c of this.props.tup.classes){
	    if(include_classes.includes(c)){
		cls += " "+c
	    }
	}
	return (
	    <div className={"rm-step step slug_"+slug+cls}>
		{this.state.is_loading ? loader : null }

	    	<div className="header" >
		    <div className="label">{label_txt}</div>
		</div>
		<div className="body">
		{this.render_body()}
	    {this.render_substeps()}
	    {this.render_l2_calc()}
		</div>
	    </div>
	)
    }
}

export default RmStep
