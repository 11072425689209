import * as React from 'react'
interface MyProps {
    btn_txt?:string
    warning?:string
    children:any
}
type MyState = {
    open:boolean
}
class Lightbox extends React.PureComponent<MyProps,MyState> {
    private myref = React.createRef<HTMLDivElement>()

    constructor(props: MyProps) {
        super(props)

	this.close_me =this.close_me.bind(this)
	this.open_me =  this.open_me.bind(this)
	
	this.state = {
	    open:false
	}
    }

    open_me(e:any){
	this.setState({open: true})
    }
    close_me(e:any){
	console.log('close-me')
	this.setState({open:false})
    }
    render_warning(){
	let cls = this.state.open ? "open" : ""
	return (
	    <div ref={this.myref} className={"lightbox-container "+cls}>
		warning
		<div className="lightbox-inner-container lightbox-narrow rounded-shadow-container">
		    <span onClick={this.close_me} className="close-button close-lightbox"></span>
		    {this.props.children}
		</div>
	    </div>
	)
    }
    componentDidMount(){
	if(this.props.btn_txt === undefined){
	    this.setState({'open':true})
	}
	if(this.props.warning !== undefined){
	    console.log('mount met warning')
	    this.setState({open:this.props.warning.length > 0 })
	}

    }
    
    render(){
	if(this.props.warning !== undefined){
	    return this.render_warning()
	}
	let btn_text = ""

	let with_btn = true
	if(this.props.btn_txt !== undefined){
	    btn_text = this.props.btn_txt
	}
	let btn_elm = with_btn ? (
	    <a href="#" className="button button-open button-next smaller-text button-full-width open-lightbox"
	    onClick={this.open_me}>
		<span className="button-text">{btn_text}</span>
		</a>
	) : null

	let cls = this.state.open ? "open" : ""

	return (
	    <div ref={this.myref} className={"lightbox-container "+cls}>
		{btn_elm}
		<div className="lightbox-inner-container lightbox-narrow rounded-shadow-container">
		    <span onClick={this.close_me} className="close-button close-lightbox"></span>
		    {this.props.children}
		</div>
	    </div>
	)
    }
}

export default Lightbox
