import * as React from 'react'

import HdBlockList from './HdBlockList'

interface MyProps {
    post_id: string,
    user:any,
    filter:any,
    view:string,
    devmode:boolean,
    techindex:any,
    blocktype:string
}
type MyState = {
    tech:string
}

class HdRmTechs extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    tech:''
	}
    }
    componentDidMount(){
	console.log('check techniek in query-params')
	let p = new URLSearchParams(window.location.search)
	let t = p.get('techniek')
	if(t !== null){
	    this.setState({tech:t})
	}
	if(this.props.view === "rm_summary" ||
	    this.props.view === "mini_summary"
	    ){
	    this.setState({tech: 't1'})
	}
    }
    click_nav(k:string){
	console.log(`click nav item ${k}`)
	this.setState({tech:k})
	const url = new URL(window.location.href)
	url.searchParams.set("techniek", k)
	window.history.pushState({}, "", url)

    }
    render_nav(){
	if(this.state.tech === undefined || this.state.tech === ""){
	return (
	    <div className="techniek-nav">
	    	{Object.entries(this.props.techindex).map(([k,v]:[string,any],i:number)=>{
		    return (
			<div key={i} className="nav-item" onClick={()=>this.click_nav(k)}>Techniek:  {v}</div>
		    )
		})}
	    </div>
	)
	}
    }
    render_app(){
	if(this.state.tech === undefined || this.state.tech === ""){
	    return null
	}
	let p = this.props
	return (
	    <HdBlockList
		post_id={p.post_id}
		user={p.user}
		blocktype={p.blocktype}
	    view={p.view}
	    devmode={p.devmode}
		filter={p.filter}
		tech={this.state.tech}
	    />
	)
    }
    render(){
	return (
	    <div>
	
		 {this.render_nav()}
		{this.render_app()}
	    </div>
	)
    }
}

export default HdRmTechs
