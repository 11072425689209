import * as React from 'react'
import WpApi from '../wpapi'

interface MyProps {
    data:any
}
type MyState = {

}
class SummaryTable extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)
	this.state = {

	}
    }
    render_summary_table(){
	if(this.props.data.calc === undefined){
	    return null
	}
	let steps = this.props.data.calc.steps
	let totaal_1 = ""
	let totaal_2 = ""

	let ret = steps.map((tup:any,i:number ) => {
	    let r = tup.calculated.result
	    let col = tup.calculated.col
	    
	    let rcol = r[col]
	    let v = ""
	    if(rcol === undefined){
		console.log(`undef col for ${tup.slug}`)
		v = r.value
	    }else{
		v = rcol.value
	    }
	    let val = Number(v).toFixed()
	    let val_1 = "0"
	    let val_2 = "0"
	    if(tup.classes.includes('column2')){
		val_2 = val
	    }else{
		val_1 = val
	    }
	    if(tup.classes.includes('totaal')){
		if(tup.slug === 'totaal_gas'){
		    totaal_1 = val
		}
		if(tup.slug === 'totaal_zw'){
		    totaal_2 = val
		}
		return null
	    }
	    return { label : tup.label, v1: val_1, v2: val_2}
	})
	ret.push({
	    label: 'Totaal',
	    v1: totaal_1,
	    v2: totaal_2
	})

	return ret.map((tup:any,i:number) => {
	    if(tup === null){return null}
	    return (
		<tr key={i}>
		    <td> {tup.label} </td>
		    <td> {tup.v1} </td>
		    <td> {tup.v2} </td>
		    </tr>
	    )
	})
	
	
    }
    render_results_summary(){
	
	return (
		
		<div className="zw-block zw-block-bg bg-white desktop-border-medium-light medium-space-below">
                    
                    <h2>Overstappen op zonnewarmte</h2>
                    
		    <table>
			<thead>
                            <tr>
                                <td className="title">Kosten per jaar</td>
                                <td className="amount">Situatie op gas</td>
                                <td className="amount">Zonne- warmte</td>
                            </tr>
			</thead>
			<tbody>
		{this.render_summary_table()}
			</tbody>
		    </table>
		    
		    
                    <div className="disclaimer element-space-below">
                        <h4>Disclaimer</h4>
                        <ul>
                            <li>Deze ruwe inschatting voor jouw situatie wordt steeds preciezer als je meer vragen beantwoordt.</li>
                            <li>Uitgangspunt voor beide situaties is een voldoende geïsoleerde woning.</li>
                        </ul>
                    </div>
		    
                    <a href="#" className="text-link element-space-below">Hoe wordt dit berekend?</a>
		    
                </div>
		
	)
    }
    render(){
	if(this.props.data === null){
	    return (<div></div>)
	}else{
	    return this.render_results_summary()
	}
    }

}
export default SummaryTable
