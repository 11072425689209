import * as React from 'react'
import WpApi from '../wpapi'

interface MyProps {
    cb_parent: ()=>void
}
type MyState = {
    values:any
    warning:string
}
class UserRegistration extends React.PureComponent<MyProps,MyState> {
    private fields = [
	    { slug: 'firstname', label: 'Jouw voornaam'},
	    { slug: 'lastname', label: 'Jouw achternaam'},
	    { slug: 'email', label: 'Jouw e-mail'},
	    { slug: 'passwd', label: 'Kies wachtwoord'},	    
	]
    constructor(props: MyProps) {
        super(props)

	this.on_submit_click = this.on_submit_click.bind(this)
	this.state = {
	    warning:'',
	    values: {}
	}
    }    
    input_change(e:any,field:string){
	let val = e.target.value
	console.log(` ${field} : ${val}`)
	
	const values = { ...this.state.values, [field]:val }
	this.setState(() => ({ values }))
	
    }
    validate():boolean{
	for(var tup of this.fields){
	    let slug = tup.slug
	    if(this.state.values[slug] === undefined){
		console.log(`oops for ${slug} `)
		return false
	    }
	}
	return true
	
    }
    on_submit_click(e:any){
	console.log('click submit')
	if(this.validate() === true){
	    console.log('do http post')
	    let path = '/aanmeldflow'
	    let d = {
		...this.state.values,
		level: 'register'
	    }
	    WpApi.do_post(path, d,(r) => {
		console.log(r)
		if(r.status === "ok"){
		    console.log('ok')
		    this.props.cb_parent()
		}else{
		    this.setState({'warning':r.code})
		}
	    })
	}else{
	    console.log('validation failed')
	}
	
    }
    render(){
	return (
	    <div>
		<h3>Maak account aan</h3>
		<div>Hierdoor kun je	altijd terug naar jouw persoonlijke pagina
                   met ingevulde gegevens van jouw woning.</div>

		{this.fields.map((tup:any,i:number) => (
		    <div key={i}>
			<div>{tup.label}</div>
			<input name={tup.slug} onChange={(e)=>this.input_change(e,tup.slug)} />
		    </div>
		)
		)}
		<div className="submit-knop" onClick={this.on_submit_click}>
		  <a className="button button-open button-next smaller-text" >Maak mijn account voor Zonnewarmte aan</a>
		</div>
		<div> {this.state.warning} </div>
		
	    </div>
	)
    }
}

export default UserRegistration
