import * as React from 'react';

import {advise_type, render_advise} from '../Advise'


import './css/HdaVotesGrid.css';

import WpApi from '../wpapi'
import Loader from '../Loader'
import Loco from '../Loco'

interface MyProps {
    post_id: string,
    cb_parent:(e?: any) => void
}


type MyState = {
    aspect:string,
    post_id:string,
    choices:Array<any>,
    top3: Array<string>,
    winner:string,
    level2:string,
    loading:boolean,
    votetype:string,
    bouwdeel:string,
    is_final_field:boolean,
    compactview:boolean,
    acf_key:string,
    acf_key_step:string,
    acf_key_select:string,
    label1: string,
    editing_choice:boolean,
    editing_attr:any,
    attr_id: string,
    invalid_input:boolean,
    trigger_recalc:boolean,
    prepend:string,
    append:string,
    pattern:string

};

class HdaVoteGrid extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";

    private comment_textarea =  React.createRef<HTMLTextAreaElement>();
    private advise_price_input =  React.createRef<HTMLInputElement>();
    private advise_gain_input =  React.createRef<HTMLInputElement>();
    private number_input = React.createRef<HTMLInputElement>();
    private text_input = React.createRef<HTMLInputElement>();
    private advise_textarea =  React.createRef<HTMLTextAreaElement>();
    

    constructor(props: MyProps) {
        super(props)
	this.check_validity = this.check_validity.bind(this)
	this.handle_chosen_option = this.handle_chosen_option.bind(this)
	this.handle_trash_clicked = this.handle_trash_clicked.bind(this)
	this.init_choice = this.init_choice.bind(this)
	this.handle_save_comment = this.handle_save_comment.bind(this)
	this.handle_save_advise = this.handle_save_advise.bind(this)
	this.handle_save_number = this.handle_save_number.bind(this)
	this.handle_save_text = this.handle_save_text.bind(this)
	this.handle_edit_btn_click = this.handle_edit_btn_click.bind(this)
	
	this.state = {
	    aspect:"",
	    post_id:"",
	    choices:[],
	    top3:[],
	    loading:true,
	    votetype: 'choice',
	    bouwdeel:'',
	    winner:'',
	    level2:'',
	    is_final_field:false,
	    compactview:false,
	    acf_key_step:'',
	    acf_key_select:'',
	    acf_key:'',
	    attr_id:'',
	    pattern:'',
	    prepend:'',
	    append:'',
	    label1: Loco.tr('choose_preferred_option'),
	    editing_choice:false,
	    invalid_input:false,
	    trigger_recalc:false,
	    editing_attr:{}
	}
    }

    // radio-option clicked (can be a comment / number / choice / advise / .. )
    public handle_chosen_option(e:any){
	console.log("handle cb ; voted for some given option")
	let val = e.target.getAttribute('value')
	// send signal to parent : the VoteGridModal
	let acfkey = this.state.acf_key_step
	if( acfkey === ""){
	    acfkey = this.state.acf_key
	}
	this.props.cb_parent({
	    kind: 'chosen_option',
	    votetype:this.state.votetype,
	    post_id:this.state.post_id,
	    value: val,
	    level2: this.state.level2,
	    aspect: this.state.aspect,
	    bouwdeel:this.state.bouwdeel,
	    is_final_field: this.state.is_final_field,
	    trigger_recalc:this.state.trigger_recalc,
	    acf_key: acfkey,
	    acf_key_select: this.state.acf_key_select,
	    on_attr_id:this.state.attr_id
	})

    }
    public handle_trash_clicked(a:any){
	console.log("remove vote..")
	console.log(a)
	this.props.cb_parent({
	    kind: 'remove_vote',
	    attr_id: a.id,
	    is_final_field: this.state.is_final_field,
	    
	})

    }
    handle_save_text(){
	console.log("save txt")
	if(this.state.invalid_input){
	    console.log('invalid input')
	    return;
	}
	let val = this.text_input.current!.value
	console.log(` val: ${val}`)
	if(val.trim() === ""){
	    console.log("value is empty")
	    return
	}
	if(this.state.editing_choice){
	    console.log('update text')
	}else{
	    this.props.cb_parent({
		kind: 'add_text',
		aspect: this.state.aspect,
		val: val,
		acf_key:this.state.acf_key_step,
	    })
	}
	
    }
    handle_save_number(){
	console.log("save number")
	if(this.state.invalid_input){
	    console.log('invalid input')
	    return;
	}
	let val = this.number_input.current!.value
	if(val.trim() === ""){
	    console.log("value is empty")
	    return
	}
	console.log(` val: ${val}`)
	if(this.state.editing_choice){
	    console.log('update number')
	}else{
	    this.props.cb_parent({
		kind: 'add_number',
		aspect: this.state.aspect,
		val: val,
		acf_key:this.state.acf_key_step,
	    })
	}
    }
    handle_save_advise(){
	console.log("save advice")
	if(this.state.invalid_input){
	    console.log('invalid input')
	    return;
	}
	let adv :advise_type = {
	    price: this.advise_price_input.current!.value,
	    gain: this.advise_gain_input.current!.value,
	    text: this.advise_textarea.current!.value
	}
	console.log(adv)
	let adv_s = JSON.stringify(adv)
	if(this.state.editing_choice){
	    this.props.cb_parent({
		kind: 'update_advise',
		votetype: 'update_advise',
		attr: this.state.editing_attr,
		value: adv_s
	    })
	}else{
	    this.props.cb_parent({
		kind: 'add_advise',
		txt: adv_s,
		aspect: this.state.aspect,
		acf_key:this.state.acf_key,
		level2: this.state.level2,
		bouwdeel:this.state.bouwdeel
	    })
	}

    }

    handle_save_comment(){
	console.log("save comment")
	let el = this.comment_textarea.current!
	var d : {[key:string]:string} = {}
	if(this.state.editing_choice){
	    d = {
		kind: 'update_comment',
		votetype: 'update_comment',
		attr: this.state.editing_attr,
		level2: this.state.level2,
		comment: el.value
	    }
	    if(this.state.attr_id){
		d.on_attr_id = this.state.attr_id
	    }
	    this.props.cb_parent(d)
	    
	}else{
	    d = {
		kind: 'add_comment',
		post_id: this.state.post_id,
		level2: this.state.level2,
		txt: el.value
	    }
	    if(this.state.attr_id){
		d.on_attr_id = this.state.attr_id
		d.acf_key = this.state.acf_key
		d.aspect = this.state.aspect
	    }else{
		d.aspect = this.state.aspect
		d.acf_key = this.state.acf_key_step
		d.bouwdeel = this.state.bouwdeel
	    }
	    
	    this.props.cb_parent(d)
	}
    }
    public init(data:any){
	let post_id = this.props.post_id
	let post_id_num = Number(post_id)
	if(isNaN(post_id_num)){
	    console.log(` pickup post-id from e  ( ${data.post_id} )`)
	    post_id = data.post_id
	}
	this.setState({post_id:post_id},()=>{
	    if(data.cmd === "choice-vote"){
		this.init_choice(data)
	    }else if(data.cmd === "advise-comment-vote"){
		this.init_advise_comment(data)
	    }else if(data.cmd === "quotations-comment-vote"){
		this.init_quotations_comment(data)
	    }else if(data.cmd === "quotation-status-vote"){
		this.init_quotation_status(data)
	    }else if(data.cmd === "inputs-comment-vote"){
		this.init_inputs_comment(data)
	    }else if(data.cmd === "comment-vote"){
		this.init_comment(data)
	    }else if(data.cmd === "number-vote"){
		this.init_number(data)
	    }else if(data.cmd === "text-vote"){
		this.init_text(data)
	    }else if(data.cmd === "advise-vote"){
		this.init_advise(data)
	    }
	    
	})
    }
    public init_quotations_comment(data:any){
	console.log(data)
	console.log(`init_quotation_comment - ${data.attr_id}`)
	this.init_comment_on_attr(data)
    }
    public init_quotation_status(data:any){
	console.log(data)

	console.log(`init_quotation_status - ${data.attr_id}`)
	this.setState({
	    acf_key_select: data.acf_key_select,
	    acf_key_step: data.acf_key_select,
	    votetype: 'choice-on-attr',
	    aspect: data.aspect,
	    attr_id: data.attr_id,
	})
	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key_select: data.acf_key_select,
	    aspect: data.aspect,
	    on_attr_id: data.attr_id
	}

	WpApi.do_get('/choice-votes',d, (r) => {
	    this.got_value_choices(r)
	    this.setState({loading:false})
	})
	
    }
    public init_advise_comment(data:any){
	console.log(data)
	console.log(`init_advise_comment - ${data.attr_id}`)
	this.init_comment_on_attr(data)
    }
    public init_comment_on_attr(data:any){
	this.setState({
	    attr_id: data.attr_id,
	    votetype:'comment',
	    aspect:data.aspect,
	    acf_key:data.acf_key,
	    top3: data.top3.map((x:any)=>x.id)
	})
	data.on_attr_id = data.attr_id
	WpApi.do_get('/comment-votes', data,(r) => {
	    this.got_comment_choices(r)
	    this.setState({loading:false})
	})
    }
    public init_inputs_comment(data:any){
	console.log('inputs-comment-vote')
	console.log(data)
	this.setState({
	    attr_id: data.attr_id,
	    votetype:'comment',
	    acf_key_step: data.acfkey,
	    level2:'NOT_LEVEL2',
	    label1: Loco.tr('choose_preferred_comment'),
	    aspect: data.aspect
	})
	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key: data.acfkey,
	    level2:'NOT_LEVEL2',
	    aspect: data.aspect
	}
	if( data.attr_id){
	    d.on_attr_id = data.attr_id
	}
	
	WpApi.do_get('/comment-votes', d,(r) => {
	    this.got_comment_choices(r)
	    this.setState({loading:false})
	})
	
    }
    public init_comment(data:any){
	console.log(`init_comment - ${data.aspect}`)
	console.log(data)
	let lvl2 = data.level2 === undefined ? 'NOT_LEVEL2' : data.level2
	this.setState({
	    aspect:data.aspect,
	    acf_key_step: data.acf_key_step,
	    acf_key_select: data.acf_key_select,
	    votetype:'comment',
	    level2: lvl2,
	    bouwdeel: data.bouwdeel,
	    label1: Loco.tr('choose_preferred_comment'),
	    loading:true
	})

	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key: data.acf_key_step,
	    level2: lvl2,
	    aspect: data.aspect
	}
	if(data.bouwdeel !== undefined && data.bouwdeel !== null){
	    d.bouwdeel = data.bouwdeel
	}
	WpApi.do_get('/comment-votes', d,(r) => {
	    this.got_comment_choices(r)
	    this.setState({loading:false})
	})
	
    }
    public init_choice(data:any){
	console.log(`init_choice - ${data.aspect} cmd: ${data.cmd}`)
	console.log(data)
	let label1 = this.state.label1
	/* if(data.bouwdeel !== undefined && data.bouwdeel !== ""){
	    label1 = Loco.tr( 'choose_preferred_judgement')
	}*/
	let lvl2 = data.level2 === undefined ? 'NOT_LEVEL2' : data.level2
	// fetch existing votes
	this.setState({
	    aspect:data.aspect,
	    acf_key_step: data.acf_key_step,
	    acf_key_select: data.acf_key_select,
	    label1: label1,
	    level2: lvl2,
	    votetype:'choice',
	    trigger_recalc: data.trigger_recalc,
	    bouwdeel: data.bouwdeel,
	    is_final_field: data.is_final_field,
	    loading:true
	})

	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key_select: data.acf_key_select,
	    acf_key_step: data.acf_key_step,
	    level2: lvl2,
	    aspect: data.aspect,
	}
	if(data.bouwdeel !== undefined && data.bouwdeel !== null){
	    d.bouwdeel = data.bouwdeel
	}



	WpApi.do_get('/choice-votes',d, (r) => {
	    this.got_value_choices(r)
	    this.setState({loading:false})
	})
    }
    public init_advise(data:any){
	
	let aspect = data.aspect
	console.log(`init_advise - ${aspect} cmd: ${data.cmd}`)
	console.log(data)
	// fetch existing votes
	this.setState({
	    aspect:aspect,
	    compactview: data.compactview,
	    acf_key: data.acf_key,
	    votetype:'advise',
	    bouwdeel: data.bouwdeel,
	    level2: data.level2,
	    label1: Loco.tr('choose_preferred_advise'),
	    top3: data.top3,
	    loading:true
	})

	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key: data.acf_key,
	    aspect: data.aspect,
	    level2:data.level2,
	    bouwdeel: data.bouwdeel
	}

	WpApi.do_get('/advise-votes', d,(r) => {
	    this.got_advise_choices(r)
	    this.setState({loading:false})
	})
	
    }
    public init_text(data:any){
	console.log('init_number')
	console.log(data)
	this.setState({
	    votetype:'text',
	    aspect: data.aspect,
	    pattern: data.pattern,
	    winner:data.winner,
	    acf_key_step: data.acfkey
	})
	WpApi.do_get('/text-votes', {
	    post_id: this.state.post_id,
	    aspect: data.aspect,
	    acf_key: data.acfkey
	},(r) => {
	    this.got_text_choices(r)
	    this.setState({loading:false})
	})
	
    }
    init_number(data:any){
	console.log('init_number')
	console.log(data)
	this.setState({
	    votetype:'number',
	    aspect: data.aspect,
	    pattern: data.pattern,
	    trigger_recalc: data.trigger_recalc,
	    top3: data.top3,
	    winner:data.winner,
	    acf_key_step: data.acfkey
	})
	WpApi.do_get('/number-votes', {
	    post_id: this.state.post_id,
	    aspect: data.aspect,
	    acf_key: data.acfkey
	},(r) => {
	    this.got_number_choices(r)
	    this.setState({loading:false})
	})
    }
    got_text_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []
	let winner_id = r.winner
	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length

	    clist.push({
		attr_id: a.id,
		value: a.id,
		label: a.value,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		is_top3: this.state.top3.includes(a.id),
		is_winner: (a.id === winner_id)
	    })
	}
	this.setState({choices: clist, winner: winner_id, prepend: r.field.prepend, append: r.field.append})
	

    }
    got_number_choices(r:any){
	console.log(r)
	var clist = []
	let winner_id = r.winner
	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length

	    clist.push({
		attr_id: a.id,
		value: a.id,
		label: a.value,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		is_top3: this.state.top3.includes(a.id),
		is_winner: (a.id === winner_id)
	    })
	}
	this.setState({choices: clist, winner: winner_id, prepend: r.field.prepend, append: r.field.append})
	
    }
    
    public got_value_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []
	
	for(let [val,lab] of Object.entries(r.field.choices)){
	    var alist = r.attr.filter((a:any) =>  a.value === val)
	    let mycount = alist.filter((x:any)=> x.mine).length
	    var tup = {
		value: val,
		label: lab,
		votes: alist,
		my_choice: (mycount > 0),
		is_winner: (val === r.winner)
	    }
	    if(val !== 'kies')  clist.push(tup)
	}
	console.log(clist)
	
	this.setState({choices: clist})
    }
    public got_advise_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []
	for(var a of r.attr){
	    let adv :advise_type = JSON.parse(a.value)
	    let label = render_advise(adv,this.state.compactview)
	    let mycount = a.votes.filter((x:any)=> x.mine).length
	    let othercount = a.votes.filter((x:any)=> !x.mine).length

	    clist.push({
		attr_id: a.id,
		value: a.id,
		advise: adv,
		label: label,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		othercount:othercount,
		is_winner: this.state.top3.includes(a.id)
	    })
	}
	this.setState({choices: clist})
    }
    public got_comment_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []

	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length
	    let othercount = a.votes.filter((x:any)=> !x.mine).length
	    let iswinner = this.state.attr_id === ""
		? (r.winner === a.id)
		: this.state.top3.includes(a.id)

	    clist.push({
		attr_id: a.id,
		value: a.id,
		label: a.value,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		othercount:othercount,
		is_winner: iswinner
	    })
	}
	this.setState({choices: clist})
    }
    
    public render_vote(a:any,i:number){
	let name = a.mine ? 'jou' : a.display_name
	return (
	    <div className="vote" key={i}>
		<div className="label">Deze is gekozen op {a.date} door {name} [PT {a.profile_type} {a.ranking}]</div>

		{a.mine ? (
		    <span data-balloon="Verwijder keuze" data-balloon-pos="up" onClick={(e)=>{this.handle_trash_clicked(a) }} className="cf-icon-btn icon-large">
			<i className="bb-icon-l bb-icon-trash"/>
			</span>
		):''}

	    </div>)
    }
    /*
     *  Button click to edit 1 comment or advise choice.
     *   reusing the form to add a new comment or advise
     */
    handle_edit_btn_click(e:any,tup:any){
	console.log(e.target)
	this.setState({
	    editing_choice:true,
	    editing_attr:tup
	})
	console.log(tup)
	if(this.state.votetype === 'advise'){
	    let ta = this.advise_textarea.current!
	    let gain = this.advise_gain_input.current!
	    let price = this.advise_price_input.current!
	    ta.value = tup.advise.text === undefined ? '' : tup.advise.text
	    gain.value = tup.advise.gain
	    price.value = tup.advise.price
	}
	if(this.state.votetype === 'comment'){
	    let ta = this.comment_textarea.current!
	    ta.value = tup.label
	}
	
    }
    public render_choice(tup:any,i:number){
	var key = tup.value
	var win_mark = tup.is_winner ? '*' :''
	var t3_mark = tup.is_top3 ? '*':''
	let edit_btn = this.state.votetype === "number" ? null : (
	    <span className="cf-icon-btn"
	    data-balloon="Bewerk" data-balloon-pos="up"
	    onClick={(e:any)=>{this.handle_edit_btn_click(e,tup)}}><i className="bb-icon-l bb-icon-edit"/></span>
	)
	if(tup.othercount !== undefined && tup.othercount > 0){
	    let txt = "Niet meer aanpasbaar"
	    edit_btn = (
		<span className="cf-icon-btn disabled" data-balloon={txt} data-balloon-pos="up">
		    <i className="bb-icon-l bb-icon-edit"/></span>
	    )
	}
	return (
	    <div className="choice-folder" key={key}>
	    <div className="choice" >
		<input name="val" type="radio" defaultChecked={tup.my_choice}
	            id={key} value={key} onClick={this.handle_chosen_option}/>
		<label className="label" htmlFor={key}>  {tup.label} {t3_mark}{win_mark}  </label>
		{tup.mine ? edit_btn : null}
	    </div>	    
		<div className="votes">
		  {tup.votes.map((a:any,i:number) => this.render_vote(a,i))}
	    </div>
		</div>

	)
    }
    check_validity(e:any) {
	console.log("change")
	if (e.target.checkValidity()) {
	    this.setState({invalid_input:false})
	    e.target.parentElement!.classList.remove('invalid')
	} else {
	    e.target.parentElement!.classList.add('invalid')
	    this.setState({invalid_input:true})
	}
    }
    
    render_footer(){
	let choice_count = Object.entries(this.state.choices).length

	if(this.state.votetype === 'comment'){
	    let lbl = choice_count > 0 ?  Loco.tr('or_write_your_comment') :  Loco.tr('write_your_comment')
	    return (
		<div className="footer">		
		    <div className="formfields add-comment">
			
			{this.state.editing_choice ? Loco.tr('change_comment_and_save')  : lbl }
			<textarea ref={this.comment_textarea} placeholder={Loco.tr('write_your_comment_here')}></textarea>
			<span onClick={this.handle_save_comment}
			      data-balloon="Opslaan" data-balloon-pos="up"
			      className="cf-icon-btn larger"><i className="bb-icon-l bb-icon-save"/></span>
	            </div>
		    {this.state.attr_id !== "" ? <div className="explain"></div> : null}
		</div>
	    )
	}else if(this.state.votetype === 'advise'){
	    let lbl = choice_count > 0 ? Loco.tr('or_write_your_advise') : Loco.tr('write_your_advise')
	    let cls = this.state.compactview ? "hidden-field" : "field"
	    return (
		<div className="footer">
		    <div className="formfields add-advise">
			{this.state.editing_choice ? Loco.tr('change_advise_and_save') : lbl }
			<div className="textarea-wrap"> <textarea ref={this.advise_textarea} placeholder={Loco.tr('write_your_advise_here')} /> </div>
			<div className={cls}> <label> Geschatte kosten:</label>
			    <input ref={this.advise_price_input} placeholder="Optioneel" />
			</div>
		        <div className={cls}><label> Geschatte aardgasbesparing:</label>
			    <input  ref={this.advise_gain_input}   placeholder="Optioneel" />
			</div>
			
			<div className="button-wrap">
			    <span onClick={this.handle_save_advise}
				  data-balloon="Opslaan" data-balloon-pos="down"
				  className="cf-icon-btn larger"><i className="bb-icon-l bb-icon-save"/></span>
			</div>
	            </div>
		</div>
	    )
	}else if(this.state.votetype === 'choice'){
	    return (
		<div className="footer">
		    <div className="explain"></div>
		</div>
	    )
	}else if(this.state.votetype === 'text'){
	    let lbl = choice_count > 0 ? Loco.tr('or_add_your_text') : Loco.tr('add_your_text')
	    return (
		<div className="footer">
		    {lbl}
		    <div className="text-input">
		    <span>{this.state.prepend} </span>
		    <input ref={this.text_input}  onChange={this.check_validity} />
		    <span> {this.state.append}</span>
		    </div>
		    <span onClick={this.handle_save_text}
			      data-balloon="Opslaan" data-balloon-pos="up"
			      className="cf-icon-btn larger"><i className="bb-icon-l bb-icon-save"/></span>
		    <div className="explain">
		     <div>(**) {Loco.tr('explain_winner_txt')}</div>
		    <div> (*) {Loco.tr('explain_top_3_txt')} </div>
		</div>
		</div>
	    )
	}else if(this.state.votetype === 'number'){
	    let lbl = choice_count > 0 ? Loco.tr('or_add_your_number') : Loco.tr('add_your_number')
	    return (
		<div className="footer">
		    {lbl}
		    <div className="number-input">
		    <span>{this.state.prepend} </span>
		    <input ref={this.number_input} pattern={this.state.pattern} onChange={this.check_validity} />
		    <span> {this.state.append}</span>
		    </div>
		    <span onClick={this.handle_save_number}
			      data-balloon="Opslaan" data-balloon-pos="up"
			      className="cf-icon-btn larger"><i className="bb-icon-l bb-icon-save"/></span>
		    <div className="explain">
		     <div>(**) {Loco.tr('explain_winner_txt')}</div>
		    <div> (*) {Loco.tr('explain_top_3_txt')} </div>
		</div>
		    </div>
	    )
	}
	
	return  (<div></div>)
    }

    public render(){
	let count = Object.entries(this.state.choices).length
	var table = (
	    <div className="choices-table">
		<div className="choice-header">
		    { count > 0 ? (  <div>{this.state.label1}</div> ) : null}
		</div>
		{ this.state.choices.map((tup:any,i:number) => this.render_choice(tup,i))}
	    </div>
	)
	return (
	    <div className="vote-grid {this.state.votetype}" >
	      {this.state.loading ? <Loader /> : null}
	      {this.state.editing_choice ? null : table}
	      {this.render_footer()}
  	    </div>
		
	);

    }
}
export default HdaVoteGrid;
